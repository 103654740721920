/* Datenschutz.css */

/* Container for overall layout specific to Datenschutz page */
.datenschutz-container {
    margin: 40px auto;
    padding: 0 20px;
    max-width: 1200px;
}

ul {
    list-style-type: disc; /* Keep the bullet points */
    padding-left: 0;       /* Remove default padding on the left */
    margin: 0;             /* Remove default margin */
    text-align: center;     /* Center the text inside the list */
}

.datenschutz-list-elem {
    display: block;         /* Set list items to block level to occupy full width */
    text-align: left;      /* Align text to the left */
    margin: 10px 0;        /* Add spacing between items */
}

/* Title styling specific to Datenschutz page */
.datenschutz-info-title {
    font-size: 1.8rem; /* Increased font size for better emphasis */
  padding-top: 90px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; /* Bold the title for better visibility */
  color: #333; /* Darker color for better contrast */
  letter-spacing: 0.05em; /* Add some letter spacing */
}

/* Main text styling specific to Datenschutz page */
.datenschutz-main-text {
    font-size: 1.125rem;
    margin: 20px auto;
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
    max-width: 800px;
    line-height: 1.8;
    color: #444;
    hyphens: auto;            /* Enable hyphenation */
    -webkit-hyphens: auto;    /* For Safari */
    -moz-hyphens: auto;       /* For Firefox */
    word-break: break-word;   /* Handle very long words */
}

/* Styling for headings within main text specific to Datenschutz page */
.datenschutz-main-text h2 {
    margin-top: 30px;
    font-size: 1.6rem;
    color: #222;
}

.datenschutz-main-text h3 {
    margin-top: 20px;
    font-size: 1.4rem;
    color: #333;
}

.datenschutz-main-text h4 {
    margin-top: 15px;
    font-size: 1.25rem;
    color: #444;
}

/* Paragraph styling within main text specific to Datenschutz page */
.datenschutz-main-text p {
    margin-bottom: 20px;
}

/* Centered List specific to Datenschutz page */
.datenschutz-centered-list {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 20px auto;
    list-style-type: none;
    text-decoration: underline;
}

.datenschutz-centered-list li {
    margin: 0 15px;
}

.datenschutz-centered-list a {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.125rem;
    text-decoration: none;
}

.blurred {
    filter: blur(4px); /* Apply blur */
    pointer-events: none; /* Prevent interactions when blurred */
  }