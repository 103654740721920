/* General reset and box-sizing rules */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Slideshow container setup */
.slideshow-container {
  position: relative;
  width: 100%;
  height: 600px; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.slideshow.blurred {
  filter: blur(3px); /* Adjust blur strength as needed */
}

/* Slideshow setup */
.slideshow-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slideshow-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slideshow-slide-active {
  opacity: 1;
}

/* Button styling */
.slideshow-btn {
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 30px; /* Increased padding for a more inviting feel */
  background-color: #656b7e; /* Slightly lighter shade for a softer look */
  color: #ffffff;
  border: none;
  border-radius: 50px; /* More rounded for a modern look */
  cursor: pointer;
  font-size: 1.7rem;
  z-index: 10;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transition for hover effect */
  text-decoration: none;
  font-weight: 600; /* Boldened text to make it stand out */
  text-align: center;
  white-space: nowrap;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.342); /* Added subtle shadow for depth */
}

.slideshow-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Slogan styling */
.slideshow-slogan {
  position: absolute;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  width: 100%; /* Full width of the screen */
  color: white;
  text-align: center; /* Center text within the full-width container */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  font-family: 'Open Sans', serif;
  padding: 10px 0; /* Adjust padding */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  font-size: 1.8rem;
}

/* Media queries */
@media screen and (min-width: 768px) {
  .slideshow-slogan {
    top: 40%; /* Move slogan down for larger screens */
  }

  .slideshow-container {
    min-height: 700px; /* Adjust height for larger screens */
  }
}

@media screen and (min-width: 1920px) {
  .slideshow-slogan {
    font-size: 3rem; /* Larger font size for very large screens */
  }

  .slideshow-container {
    min-height: 600px; /* Adjust height for larger screens */
  }
}

@media screen and (max-width: 767px) {
  .slideshow-slogan {
    top: 35%; /* Move slogan down for smaller screens */
  }

  
}
