/* Impressum.css */

/* Container für das Impressum-Layout */
.impressum-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container für den Textbereich */
.impressum-text-container { 
  margin: 40px 15%; /* top, horizontal auto, bottom */
  padding: 0 20px; /* padding für bessere Abstände */
  max-width: 1200px; /* Maximalbreite für bessere Lesbarkeit auf großen Bildschirmen */
  margin-left: auto;
  margin-right: auto;
}

/* Titel-Styling */
.impressum-info-title {
  font-size: 1.8rem; /* Increased font size for better emphasis */
  padding-top: 90px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; /* Bold the title for better visibility */
  color: #333; /* Darker color for better contrast */
  letter-spacing: 0.05em; /* Add some letter spacing */
}

/* Haupttext-Styling */
.impressum-main-text {
  font-size: 1.125rem;
  text-align: justify; /* Justifizierter Text für ein saubereres Aussehen */
  font-family: 'Open Sans', sans-serif; /* Schriftart ändern */
  max-width: 800px; /* Textbreite begrenzen für bessere Lesbarkeit */
  line-height: 1.8; /* Erhöhte Zeilenhöhe für bessere Lesbarkeit */
  color: #444; /* Etwas hellere Farbe für Kontrast zum Titel */
}

/* Styling für Überschriften im Haupttext */
.impressum-main-text h2 {
  margin-top: 30px;
  font-size: 1.5rem;
  color: #222; /* Dunklere Farbe, um Überschriften hervorzuheben */
}

.impressum-main-text h3 {
  margin-top: 20px;
  font-size: 1.5rem;
  color: #333;
}

.impressum-main-text h4 {
  margin-top: 15px;
  font-size: 1.25rem;
  color: #444;
}

/* Absatz-Styling im Haupttext */
.impressum-main-text p {
  margin-bottom: 20px; /* Erhöhter Abstand für bessere Trennung zwischen Absätzen */
}

/* Zentrierte Liste */
.impressum-centered-list {
  display: flex;
  justify-content: center; /* Liste horizontal zentrieren */
  padding: 0;
  margin: 20px auto; /* Liste innerhalb des Containers zentrieren */
  list-style-type: none; /* Standard-Aufzählungszeichen entfernen */
  text-decoration: underline;
}

.impressum-centered-list li {
  margin: 0 15px; /* Abstand zwischen Listenelementen */
}

.impressum-centered-list a {
  font-family: 'Open Sans', sans-serif; /* Schriftart ändern */
  font-size: 1.125rem;
  color: #414c58; /* Linkfarbe */
  text-decoration: none;
}
.blurred {
  filter: blur(4px); /* Apply blur */
  pointer-events: none; /* Prevent interactions when blurred */
}