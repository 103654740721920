/* App.css */

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-left: 350px;
  margin-right: 350px;
  padding: 20px;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Light semi-transparent background */
  z-index: 999; /* Make sure it’s on top of everything */
}

.blurred {
  filter: blur(1px); /* Adjusted blur intensity to 2px */
  transition: filter 0.3s ease; /* Smooth transition */
}

.info-text {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-family: 'Cambria', sans-serif;
}

@media screen and (min-width: 320px) { 
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .kontakt--button {
    padding: 10px 20px;
    background-color: rgb(111, 117, 139);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 25px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
    text-align: left;
  }

  .info-text {
    margin-bottom: 20px;
  }

  /* Modern List Styles */
  .modern-list {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .modern-list li {
    margin: 10px 0;
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .modern-list li:hover {
    background-color: #f4f4f4;
    transform: scale(1.02);
  }
  
  .modern-list li i {
    color: #6b8e23;
    margin-right: 10px;
    font-size: 1.5rem;
  }

  /* Info Details Style */
  .info-details {
    margin-top: 5px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    font-size: 1.2rem;
    color: #333;
  }

  .info-details p {
    margin: 0;
    line-height: 1.5;
  }

  .slogan {
    position: absolute;
    top: 80px;
    left: 50%;
    width: 70%;
    text-align: center; /* Center text horizontally */
    transform: translateX(-50%);
    font-size: 25px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: add a shadow for better readability */
    z-index: 10; /* Make sure the slogan is on top */
  }

  /* Blur Effect for the App when Cookie Banner is visible */
  
}
