/* General Styles */
.ue-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  padding: 20px;
  max-width: 1000px;
  text-align: center;
}

.ue-title {
  font-size: 1.8rem; /* Increased font size for better emphasis */
  margin: 20px 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; /* Bold the title for better visibility */
  color: #333; /* Darker color for better contrast */
  letter-spacing: 0.05em; /* Add some letter spacing */
}

.ue-text {
  font-size: 1.3rem; /* Reduced font size */
  margin: 15px; /* Reduced margin */
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6; /* Reduced line height */
  padding-bottom: 20px; /* Reduced padding */
  color: #555;
  max-width: 800px;
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
}


.ue-img-full {
  width: 80%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 10px;
  margin-top: 50px;
}

.ue-img-padded {
  width: 80%;
  max-width: 800px; /* Match the width of the text */
  height: auto;
  margin: 0 auto;
  padding: 0px;
  box-sizing: border-box;
}


.ue-button {
  padding: 12px 30px; /* Slightly larger padding for a more balanced look */
  background-color: #6f758b; /* Matching the lighter shade */
  color: #ffffff;
  border: none;
  border-radius: 50px; /* More rounded corners for a modern look */
  cursor: pointer;
  font-size: 1.7rem; /* Adjusted font size for consistency */
  text-decoration: none;
  margin: 20px auto;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform for hover */
  display: inline-block;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Added subtle shadow for depth */
  font-weight: 600; /* Boldened text for emphasis */
}

.ue-button:hover {
  background-color: #555a6e; /* Slightly darker color on hover */
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Enhance shadow on hover */
}

/* List Styles */
.ue-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ue-list-item {
  margin: 10px 0;
  padding: 12px 20px; /* Increased padding for better spacing */
  font-size: 1.3rem; /* Increased font size for clarity */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  background-color: #f7f7f7; /* Light background for better contrast */
}

.ue-list-item i {
  color: #6b8e23; 
  margin-right: 10px;
  font-size: 1.5rem;
}

.ue-list-item:hover {
  background-color: #e0e0e0; /* Slightly darker background on hover */
  transform: scale(1.02);
}

.ue-info {
  margin-top: 5px;
  padding: 15px; /* Increased padding for more space */
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 1.3rem; /* Slightly increased font size for readability */
  color: #444; /* Softer color for text */
  transition: all 0.3s ease;
  max-width: 800px; /* Restrict width for better readability */
  line-height: 1.7; /* Improved line height for better text flow */
}
.ue-blurred {
  filter: blur(4px); /* Adjust the blur intensity as needed */
  pointer-events: none; /* Prevent interactions with blurred content */
} 