/* Kontakt.css */

/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.kp-maps-disabled {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 10px; 
  background-color: #f9f9f9;
  max-width: 600px;
  margin: 20px auto;
}
 
.blurred {
  filter: blur(4px);
  pointer-events: none; /* Prevent interaction with blurred elements */
}

.blurred .kp-kontakt-container {
  pointer-events: auto; /* Allow interaction with the main container */
}

.kp-maps-disabled-text {
  color: #444;
  font-size: 1.3rem;
  margin-bottom: 15px;
  text-align: center;
}

.kp-enable-maps-button {
  background-color: #28a745;
  color: white;
  font-size: 1.3rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.kp-enable-maps-button:hover {
  background-color: #218838;
}

.kp-enable-maps-button:focus {
  outline: none;
}


/* Wrapper for the whole page */
.kp-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the footer stays at the bottom */
}

/* Content Container */
.kp-kontakt-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  text-align: center;
  max-width: 1200px; /* Max width for content */
  margin: 0 auto;
}

/* Title Text */
.kp-info-title {
  font-size: 1.8rem; /* Increased font size for better emphasis */
  margin-top: 90px;
  margin-bottom: 30px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; /* Bold the title for better visibility */
  color: #333; /* Darker color for better contrast */
  letter-spacing: 0.05em; /* Add some letter spacing */
}

/* General Button Styling for uniform width */
.kp-whatsapp-button, .kp-call-button, .kp-email-button {
  padding: 12px 20px;
  border: none;
  border-radius: 50px;
  font-size: 1.3rem;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
  
  /* Make all buttons have the same width */
  width: 100%;           /* Full width for flex items */
  max-width: 300px;      /* Maximum width to control stretch */
  flex-grow: 1;          /* Allow buttons to grow equally */
}

/* Individual Button Styles */
.kp-whatsapp-button {
  background-color: #25D366; /* WhatsApp green */
}

.kp-call-button {
  background-color: #007bff; /* Blue for call button */
}

.kp-email-button {
  background-color: #007bff; /* Blue for email button */
}

/* Hover Effects */
.kp-whatsapp-button:hover {
  background-color: #20b358;
  transform: scale(1.05);
}

.kp-call-button:hover {
  background-color: #0069d9;
  transform: scale(1.05);
}

.kp-email-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Icon Styling */
.kp-whatsapp-button i, .kp-call-button i, .kp-email-button i {
  margin-right: 10px;
  font-size: 1.3rem;
}

/* Ensure that all buttons are vertically stacked */
.kp-button-container {
  display: flex;
  flex-direction: column; /* Stack the buttons */
  align-items: center;    /* Center-align the buttons */
  gap: 10px;              /* Add space between buttons */
}


/* Info Text Styling */
.kp-info-text {
  font-size: 1.3rem;
  margin: 20px 0;
  font-family: 'Open Sans', sans-serif;
  
}

.kp-info-text p {
  line-height: 1.4;
}


.kp-info-text p  {
  
  margin-bottom: 10px;

}

/* Map Container Styling */
.kp-map-container {
  margin: 40px 0 60px;
  width: 100%;
  max-width: 800px;
  text-align: center;
}

.kp-map-container iframe {
  width: 100%;
  height: 450px;
  border: none;
}

/* Footer Styling */
.kp-footer {
  padding: 20px;
  background-color: #f1f1f1;
  text-align: center;
}

/* Ensure Footer is at the bottom */
.kp-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.kp-page-wrapper > *:not(.kp-footer) {
  flex: 1;
}
