.galerie-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    padding: 20px;
    max-width: 1000px;
    text-align: center;
  }
  
  .coming-soon-section {
    text-align: center;
    padding: 50px 20px;
    font-family: 'Open Sans', sans-serif;
  
  }
  
  .coming-soon-section h2 {
    font-size: 1.8rem; /* Increased font size for better emphasis */
    margin: 20px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700; /* Bold the title for better visibility */
    color: #333; /* Darker color for better contrast */
    letter-spacing: 0.05em; /* Add some letter spacing */
  }
  
  .coming-soon-section p {
    font-size: 1.3rem;
    color: #555;
    margin-bottom: 30px;  
  }
  
  .placeholder {
    font-size: 6rem;
    color: #888;
    opacity: 0.5;
  }
  
  .placeholder i {
    animation: pulse 2s infinite;
  }
  
  /* Optional animation for the icon */
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% { 
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .blurred {
    filter: blur(4px); /* Apply blur */
    pointer-events: none; /* Prevent interactions when blurred */
  }